import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import {
  IInspiration,
  IMainState,
  MeetingRole,
  Source
} from '../../interfaces';
import {
  actionRequestHideMeetingVeil,
  actionVideoPlayedWithSound,
  DID_CLICK_ON_PREVENT_INTERACTION_VEIL
} from '../../redux/actions';
import {
  AUDIO_OUTPUT,
  DID_START_VIDEO,
  VIDEO_ERROR
} from '../../utils/constants';
import { isUserOnMobile } from '../../utils/deviceDetector';
import { getDisplayedContext } from '../../utils/inspiration';
import { setAudioOutputDevice } from '../../utils/video';
import {
  getParentWindowMeetingRole,
  isParentWindowInMeeting,
  tryGetParentWindowField
} from '../../utils/window';
import { AppContext } from '../AppContext';
import CSRComponentWrapper from '../CSRComponentWrapper';
import InspirationContent from './InspirationContent';

const InspirationVideo = ({ inspiration }: { inspiration: IInspiration }) => {
  const { title } = inspiration;
  const { source } = React.useContext(AppContext);
  const isFromNudge = source === Source.Nudge;
  const dispatch = useDispatch();
  const content = inspiration.description;
  const video = inspiration.videoUrl;
  const header = getDisplayedContext(inspiration);
  const hasContent =
    header ||
    (title && title !== '' && title.length > 1) ||
    (content && content !== '' && content.length > 1);
  const autoTranslated =
    (inspiration.tagList || []).indexOf('auto_translated') > -1;
  const isMobile = isUserOnMobile();
  const [videoMute, setVideoMute] = React.useState<boolean>(
    isMobile && !isFromNudge
  );
  const [didStart, setDidStart] = React.useState(false);
  const activeAudioOutput = useSelector(
    (state: IMainState) =>
      state?.clientState?.meeting?.localUser?.activeDevices?.speaker
  );
  const playerRef = React.useRef<ReactPlayer>();
  const isInMeeting = isParentWindowInMeeting();
  const isClientInMeeting =
    isInMeeting && getParentWindowMeetingRole() !== MeetingRole.ADVISOR;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const handleVideoPlayed = (play: boolean) => {
    if (play && isClientInMeeting) {
      dispatch(actionVideoPlayedWithSound(!videoMute));
    }
  };

  const handleMessageEvent = (event: MessageEvent) => {
    if (event.data?.type === DID_CLICK_ON_PREVENT_INTERACTION_VEIL) {
      setVideoMute(false);
      dispatch(actionVideoPlayedWithSound(true));
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);

    return () => {
      dispatch(actionVideoPlayedWithSound(undefined));
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  React.useEffect(() => {
    const preventWheel = (e) => {
      if (isClientInMeeting) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    };
    const body = document.querySelector('body');
    if (isClientInMeeting) {
      dispatch(actionRequestHideMeetingVeil(true));
      body.addEventListener('wheel', preventWheel, {
        passive: false
      });
      body.addEventListener('touchmove', preventWheel, { passive: false });
    }
    return () => {
      body?.removeEventListener('wheel', preventWheel);
      body?.removeEventListener('touchmove', preventWheel);
      dispatch(actionRequestHideMeetingVeil(false));
    };
  }, [isClientInMeeting]);

  React.useEffect(() => {
    if ((playerRef.current as any)?.wrapper) {
      const wrapper = (playerRef.current as any).wrapper as HTMLDivElement;
      wrapper.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }
  }, [playerRef]);

  const output = activeAudioOutput || tryGetParentWindowField(AUDIO_OUTPUT);

  React.useEffect(() => {
    if (isInMeeting && didStart && output && playerRef?.current) {
      const videoElement = playerRef?.current?.getInternalPlayer();
      setAudioOutputDevice(videoElement, output);
    }
  }, [isClientInMeeting, didStart, output, playerRef?.current]);
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`content content-video ${
        isClientInMeeting ? 'meeting-client' : ''
      }`}
    >
      <Container>
        <Row ref={containerRef} className="align-items-center row-container">
          <Col xs={12}>
            <Row className="align-items-center content-container">
              <Col xs={12} className="media-container">
                <div className="video-container media-container">
                  <CSRComponentWrapper>
                    <ReactPlayer
                      playing
                      playsinline
                      ref={playerRef}
                      width="100%"
                      height="100%"
                      muted={videoMute}
                      controls
                      url={video}
                      onStart={() => {
                        setDidStart(true);
                        logEvent(DID_START_VIDEO, DID_START_VIDEO, {
                          videoUrl: video
                        });
                      }}
                      onPause={() => handleVideoPlayed(false)}
                      onPlay={() => handleVideoPlayed(true)}
                      onError={(e, data) => {
                        if (e.name === 'NotAllowedError') {
                          setVideoMute(true);
                        }
                        logEvent(VIDEO_ERROR, VIDEO_ERROR, {
                          error: e,
                          details: data
                        });
                        console.log('Error playing video', e);
                      }}
                      config={{
                        file: {
                          attributes: {
                            controlsList: `nodownload ${
                              isClientInMeeting ? 'nofullscreen' : ''
                            }`
                          }
                        }
                      }}
                    />
                  </CSRComponentWrapper>
                  {videoMute && !isFromNudge && (
                    <>
                      <button
                        className="btn btn-mute btn-sm btn-light"
                        onClick={() => {
                          setVideoMute(false);
                        }}
                      >
                        {i18n?.t('unmute').toUpperCase()}
                      </button>
                      <div
                        className="btn-mute2"
                        onClick={() => {
                          setVideoMute(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </Col>
              {hasContent && (
                <Col xs={12}>
                  <InspirationContent
                    header={header}
                    title={title}
                    content={content}
                  />
                </Col>
              )}
              {autoTranslated && (
                <p className="auto-translated">{t?.('auto_translated')}</p>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <style jsx>
        {`
          :global(video) {
            display: block;
            width: 100%;
          }

          .content.meeting-client ::global(.container *) {
            pointer-events: none;
            user-select: none;
          }

          .unmute-veil {
            background: #fff;
            opacity: 0.01;
            position: fixed;
            z-index: 2000;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }

          .content-video :global(.copy) {
            text-align: center !important;
          }
          .btn-mute2 {
            background: white;
            opacity: 0.01;
            position: absolute;
            z-index: 2000;
            left: 60%;
            right: 0;
            bottom: 30px;
            height: 100px;
            width: auto;
            pointer-events: all !important;
          }
          .btn-mute {
            position: absolute;
            right: 5px;
            top: 5px;
            pointer-events: all !important;
          }

          .auto-translated {
            position: absolute;
            right: 10px;
            bottom: 10px;
            margin: 0;
            font-style: italic;
            font-size: 12px;
          }
          @media (min-width: 768px) {
            .content-video :global(.copy) {
              padding: 40px !important;
            }
            :global(video) {
              max-height: 80vh;
              margin: auto;
            }
          }
        `}
      </style>
    </div>
  );
};

export default InspirationVideo;
