import {
  IShoppingCartItem,
  IShoppingCartItemDisplay
} from '../interfaces';
import {
  getProductDisplayPrice,
  getCountrySpecificPrice,
  getUnknownPrice,
  getProductDisplayName
} from './product';

export const getFormattedPrice = (price: number, currency: string) => {
  if (currency && price > 0) return `${currency} ${price.toLocaleString()}`;
  return getUnknownPrice();
};

export const getFormattedFullName = (
  firstName: string,
  lastName: string,
  salutation?: string
) => {
  return `${salutation ? salutation + ' ' : ''}${firstName} ${lastName}`;
};

export const getItemTotalPrice = (
  item: Partial<IShoppingCartItem>,
  countryCode: string
) => {
  const countrySpecificPrice = getCountrySpecificPrice(item, countryCode);
  const price = countrySpecificPrice?.salesPrice || 0;

  return {
    totalPrice: price * item.quantity,
    price,
    currency: countrySpecificPrice?.currencyCode
  };
};

export const mapItemToCartDisplay = (
  item: IShoppingCartItem,
  countryCode: string,
  language: string
): IShoppingCartItemDisplay => {
  const { totalPrice, currency, price } = getItemTotalPrice(item, countryCode);
  const modelName = getProductDisplayName(item, language);

  return {
    id: item.id,
    modelName,
    productFamily: item.productFamily,
    modelCode: item.modelCode,
    quantity: item.quantity,
    price,
    totalPrice,
    formattedTotalPrice: getFormattedPrice(totalPrice, currency),
    formattedPrice: getProductDisplayPrice(item, countryCode),
    imageUrl: item.previewImageUrl || item.imageUrls[0],
    currencyCode: currency
  };
};

export const isPossibleToSum = (
  totalPrice: number,
  previousCurrency: string,
  currency: string,
  index: number
) => {
  const hasPrice = !!totalPrice;
  const currencyIsSame = index !== 0 ? previousCurrency === currency : true;
  return hasPrice && currencyIsSame;
};

export interface CartTotalPrice {
  currency: string;
  total: number;
  formattedTotal: string;
  subTotal: number;
  formattedSubTotal: string;
  deliveryFee: number;
  formattedDeliveryFee: string;
}

export const getCartTotalPrice = (
  items: IShoppingCartItem[],
  countryCode: string,
  extraFee = 0
): CartTotalPrice => {
  let total = 0,
    formattedTotal = '',
    subTotal = 0,
    formattedSubTotal = '',
    previousCurrency = '',
    deliveryFee = 0,
    formattedDeliveryFee = '';
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const { totalPrice, currency } = getItemTotalPrice(item, countryCode);
    if (!isPossibleToSum(totalPrice, previousCurrency, currency, i)) {
      subTotal = 0;
      total = 0;
      formattedTotal = getUnknownPrice();
      subTotal = 0;
      formattedSubTotal = getUnknownPrice();
      break;
    }
    previousCurrency = currency;
    subTotal += totalPrice;
  }

  if (subTotal > 0) {
    total = subTotal + extraFee;
    formattedSubTotal = getFormattedPrice(subTotal, previousCurrency);
    formattedTotal = getFormattedPrice(subTotal + extraFee, previousCurrency);
    deliveryFee = extraFee;
    formattedDeliveryFee = deliveryFee
      ? getFormattedPrice(extraFee, previousCurrency)
      : 'Free';
  }

  return {
    deliveryFee,
    formattedDeliveryFee,
    currency: previousCurrency,
    total,
    formattedTotal,
    subTotal,
    formattedSubTotal
  };
};
