export const lightOrDark = (color) => {
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

export const lightOrDarkbyHSL = (color) => {
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  r /= 255;
  g /= 255;
  b /= 255;
  let  l = 0;

  const cmin = Math.min(r, g, b)
  const  cmax = Math.max(r, g, b)
  l = (cmax + cmin) / 2;

  if (l > 0.9) {
    return 'light';
  } else {
    return 'dark';
  }
};



export const isHexColor = (strColor) => {
  return /^#[0-9A-F]{6}$/i.test(strColor);
};

export const getValidHexColor = (inputColor, shouldSixChars = false) => {
  const hexColorRegex = /^#?([a-f\d]{3}|[a-f\d]{6})$/i;
  if (hexColorRegex.test(inputColor)) {
    const color = inputColor.startsWith('#') ? inputColor : `#${inputColor}`;
    if (shouldSixChars && color.length === 4) {
      return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
    }
    return color;
  }
  return null;
};

export const intToRGB = function (value) {
  //credit to https://stackoverflow.com/a/2262117/2737978 for the idea of how to implement
  const blue = Math.floor(180);
  const green = Math.floor(100 - value);
  const red = Math.floor(value);

  return 'rgb(' + red + ',' + green + ',' + blue + ')';
};