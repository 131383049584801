import React from 'react';
import { IInspiration } from '../../interfaces';
import Placeholder from '../Placeholder';

const InspirationHtml = ({ inspiration }: { inspiration: IInspiration }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { htmlUrl } = inspiration;
  const [iframeHeight, setIframeHeight] = React.useState('calc(100vh - 46px)');
  const handleMessageEvent = (event: MessageEvent) => {
    const height = parseInt(event.data);
    if (!isNaN(height)) {
      setIframeHeight(`${height + 120}px`);
    }
  };
  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);
  return (
    <>
      <div className="content content-html">
        {isLoading && <Placeholder />}
        {htmlUrl && (
          <iframe
            id="story-iframe"
            className="iframe"
            src={htmlUrl}
            title="Page"
            onLoad={() => setIsLoading(false)}
            style={{ height: iframeHeight }}
          />
        )}
        <style jsx>
          {`
            .iframe {
              width: 100vw;
              border: none;
              overflow-y: auto;
              overflow-x: hidden;
              display: block;
            }

            @media (min-width: 760px) and (max-width: 1200px) {
              .iframe {
                min-height: 650px;
                overflow-y: scroll;
                width: 100%;
              }
            }
          `}
        </style>
      </div>
    </>
  );
};

export default InspirationHtml;
