import React from 'react';
import { useSelector } from 'react-redux';
import { IInspiration, IMainState, MeetingRole } from '../../interfaces';
import {
  getParentWindowMeetingRole,
  getViewportSize,
  isParentWindowInMeeting
} from '../../utils/window';
import CSRComponentWrapper from '../CSRComponentWrapper';
import { TLanguage } from '../../mappers/polotno';
import { throttle } from 'lodash';
import SBMicrosite from '../../advisorHub/components/StoryBook/Mirosite/SBMicrosite';
import { mapSceneToStorybook } from '../../mappers/storybook';
import PopupPage from '../PopupPage';

const InspirationScene = ({ inspiration }: { inspiration: IInspiration }) => {
  const [portrait, setPortrait] = React.useState(true);
  const [browserLanguage, setBrowerLanguage] = React.useState<TLanguage>('en');
  const openPopup = useSelector(
    (state: IMainState) => state?.clientState?.vb?.popup?.open
  );
  React.useEffect(() => {
    setBrowerLanguage(navigator.language as TLanguage);
  }, []);
  React.useEffect(() => {
    const onViewPortResize = throttle(() => {
      const { vh, vw } = getViewportSize();
      setPortrait(vh - 50 > vw);
    }, 500);
    onViewPortResize();
    window.addEventListener('resize', onViewPortResize);
    return () => {
      window.removeEventListener('resize', onViewPortResize);
    };
  }, []);

  const isInMeeting = isParentWindowInMeeting();
  const isClientInMeeting =
    isInMeeting && getParentWindowMeetingRole() !== MeetingRole.ADVISOR;

  const scene = inspiration.scene;
  const storybook = React.useMemo(() => mapSceneToStorybook(scene), [scene]);

  return (
    <>
      <div className={`content ${isClientInMeeting ? 'meeting-client' : ''}`}>
        <div className="media-container">
          <CSRComponentWrapper>
            <SBMicrosite
              storybook={storybook}
              activateLanguage={browserLanguage}
              additionalComponent={null}
              portrait={portrait}
            />
          </CSRComponentWrapper>
        </div>

        {openPopup && <PopupPage />}
        <style jsx>
          {`
            .unmute-veil {
              background: #fff;
              opacity: 0.01;
              position: fixed;
              z-index: 2000;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
            }

            .media-container {
              width: 100vw;
              --ratio: ${portrait ? '16/9' : '9/16'};
              height: calc(100vw * var(--ratio));
            }
          `}
        </style>
      </div>
    </>
  );
};

export default InspirationScene;
