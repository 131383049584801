import { AiOutlineCalendar } from 'react-icons/ai';
import DiscoverSvgIcon from '../../../components/VirtualBoutique/Icons/DiscoverSvgIcon';
import { useTranslation } from '../../../i18n';
import { ThemeColor } from './../BrandStyle';

const NudgeMeetingButton = ({
  onClickVisit,
  onBookPrivateSession,
  appointment,
  themeColor
}: {
  onClickVisit?: () => any;
  appointment?: boolean;
  themeColor: ThemeColor;
  onBookPrivateSession?: () => any;
}) => {
  const hasDiscoverMore = onClickVisit !== undefined;
  const hasBookPrivateSessionButton = onBookPrivateSession !== undefined;

  const className = `actions-${
    Number(hasDiscoverMore) + Number(hasBookPrivateSessionButton)
  }-col`;

  if (!(hasDiscoverMore || appointment)) return null;
  const { t } = useTranslation();
  return (
    <div className={`nudge-actions ${className}`}>
      {hasDiscoverMore && (
        <button className="visit-button" onClick={onClickVisit}>
          <DiscoverSvgIcon />
          {t('vb:discover_more')}
        </button>
      )}
      {hasBookPrivateSessionButton && (
        <button className="appointment-button" onClick={onBookPrivateSession}>
          <AiOutlineCalendar color={themeColor.btnPrimaryText} />
          {t('book_private_session')}
        </button>
      )}

      <style jsx>{`
        .nudge-actions {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        .nudge-actions button {
          font-size: 1em;
          letter-spacing: 2px;
          height: 50px;
          padding: 10px 20px;
        }

        .nudge-actions :global(svg) {
          width: auto;
          height: 18px;
          margin-right: 10px;
        }

        .nudge-actions.actions-1-col button {
          max-width: 350px;
          border-radius: 6px;
          width: 100%;
        }

        .nudge-actions.actions-3-col button:first-child {
          border-radius: 6px 0 0 6px;
        }

        .nudge-actions.actions-3-col button:last-child {
          border-radius: 0 6px 6px 0;
        }

        .nudge-actions.actions-2-col button {
          padding: 0 20px;
        }

        .nudge-actions.actions-2-col button:first-child {
          border-radius: 6px 0 0 6px;
        }

        .nudge-actions.actions-2-col button:last-child {
          border-radius: 0 6px 6px 0;
        }

        .visit-button {
          background-color: #efefef;
          color: #000;
        }
        .meet-button {
          background-color: ${themeColor.btnPrimary};
          color: ${themeColor.btnPrimaryText};
        }
        .appointment-button {
          background: #ccc;
          background-color: ${themeColor.btnPrimary};
          color: ${themeColor.btnPrimaryText};
        }

        @media (max-width: 840px) {
          .nudge-actions {
            position: fixed;
            bottom: 5px;
            left: 5px;
            right: 5px;
            width: auto;
            margin-top: 0;
          }
          .nudge-actions.actions-1-col button {
            max-width: none;
            border-radius: none;
          }

          .nudge-actions.actions-3-col button {
            font-size: 0.8em;
            height: 40px;
          }

          .nudge-actions.actions-3-col button:first-child {
            border-radius: 6px 0 0;
            width: 50%;
          }
          .nudge-actions.actions-3-col button:nth-child(2) {
            border-radius: 0 6px 0 0;
            width: 50%;
          }

          .nudge-actions.actions-3-col button:last-child {
            border-radius: 0 0 6px 6px;
            width: 100%;
          }

          .nudge-actions.actions-2-col button {
            font-size: 0.9em;
            line-height: 1.2;
            height: 60px;
            width: 50%;
          }
        }

        @media (max-width: 640px) {
          .nudge-actions :global(svg) {
            display: none !important;
          }
        }
        @media (max-width: 520px) {
          .nudge-actions.actions-3-col {
            display: flex;
          }

          .nudge-actions.actions-3-col button:first-child,
          .nudge-actions.actions-3-col button:nth-child(2),
          .nudge-actions.actions-3-col button:last-child {
            border-radius: 6px;
            width: 32.5%;
            height: auto;
            padding: 10px;
            font-size: 0.7em;
          }
          .nudge-actions.actions-3-col button:nth-child(2) {
            margin: 0 1%;
          }
        }
        @media (max-height: 670px) {
          .nudge-actions {
            position: fixed;
            bottom: -5px;
            left: 5px;
            right: 5px;
            width: auto;
            margin-top: 0;
          }
        }

        @media (max-height: 420px) {
          .nudge-actions.actions-2-col button {
            height: 45px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default NudgeMeetingButton;
