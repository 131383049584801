import React from 'react';
import { FileType } from '../../../interfaces';
import { getFileType } from '../../../utils/file';
import { BsX } from 'react-icons/bs';
import ReactPlayer from 'react-player';
import {
  DID_START_VIDEO,
  VIDEO_ENDED,
  VIDEO_ERROR
} from '../../../utils/constants';
import { logEvent } from '../../../analytics';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {
  isTouchEnableDevice,
  isUserOnMobile,
  isUserOnWeChat
} from '../../../utils/deviceDetector';

const ImageContent = ({
  imgUrl,
  isTouchEnable
}: {
  imgUrl: string;
  isTouchEnable?: boolean;
}) => (
  <>
    <div className="legend">
      {isTouchEnable ? 'Pinch and pan' : 'Scroll'} to zoom
    </div>
    <TransformWrapper wheel={{ step: 100 }}>
      <TransformComponent>
        <div className="img-container">
          <img src={imgUrl} />
        </div>
      </TransformComponent>
    </TransformWrapper>
    <style jsx>{`
      .img-container {
        width: 100vw;
        height: 100vh;
        z-index: 1002;
        position: relative;
        text-align: center;
        display: flex;
      }
      img {
        width: auto;
        height: auto;
        max-width: 100vw;
        margin: auto;
        max-height: 100vh;
      }
      :global(.react-transform-component) {
        width: 100%;
        height: 100%;
      }
      .legend {
        position: fixed;
        bottom: 10px;
        right: 10px;
      }
    `}</style>
  </>
);

export const VideoContent = ({
  videoUrl,
  posterUrl,
  isMobile
}: {
  videoUrl: string;
  posterUrl?: string;
  isMobile?: boolean;
}) => {
  const [muted, setMuted] = React.useState<boolean>(!!isMobile);
  const ref = React.useRef<ReactPlayer>(null);
  return (
    <div className="video-container fixed-full">
      {videoUrl && (
        <ReactPlayer
          ref={ref}
          playing
          light={posterUrl || false}
          width="100%"
          height="100%"
          controls
          url={videoUrl}
          muted={muted}
          onStart={() => {
            ref?.current?.getInternalPlayer?.()?.requestFullscreen?.();
            logEvent(DID_START_VIDEO, DID_START_VIDEO, {
              videoUrl
            });
            setMuted(false);
          }}
          onEnded={() => {
            logEvent(VIDEO_ENDED, VIDEO_ENDED, {
              videoUrl
            });
          }}
          onError={(e, data) => {
            if (e.name === 'NotAllowedError') {
              setMuted(true);
            }
            logEvent(VIDEO_ERROR, VIDEO_ERROR, {
              error: e,
              details: data
            });
            console.log('Error playing video', e);
          }}
        />
      )}
      <style jsx>{`
        :global(video) {
          display: block;
          width: 100%;
        }
        @media (orientation: portrait) {
          .video-container {
            top: 45px;
            bottom: 45px;
          }
        }
        @media (orientation: landscape) {
          .video-container {
            left: 45px;
            right: 45px;
          }
        }
      `}</style>
    </div>
  );
};

const NudgeHiResContentPopup = ({
  contentPath,
  posterUrl,
  onClose
}: {
  contentPath: string;
  posterUrl?: string;
  onClose: () => void;
}) => {
  const [isMobile, setIsMobile] = React.useState(true);
  const [isTouchEnable, setIsTouchEnable] = React.useState(true);
  const [isWeChat, setIsWeChat] = React.useState(undefined);

  React.useEffect(() => {
    setIsMobile(isUserOnMobile());
    setIsTouchEnable(isUserOnMobile() || isTouchEnableDevice());
    setIsWeChat(isUserOnWeChat());
  }, []);

  const content =
    getFileType(contentPath) === FileType.IMAGE ? (
      <ImageContent imgUrl={contentPath} isTouchEnable={isTouchEnable} />
    ) : (
      <VideoContent
        videoUrl={contentPath}
        isMobile={isMobile}
        posterUrl={isWeChat ? posterUrl : undefined}
      />
    );

  return (
    <div className="fixed-full">
      {content}
      <div className="close-button with-shadow" onClick={onClose}>
        <BsX color="#fff" size={25} />
      </div>
      <style jsx global>{`
        .btn-nav-group {
          display: none;
        }
      `}</style>
      <style jsx>{`
        .fixed-full {
          z-index: 1000;
          background: rgba(0, 0, 0, 0.8);
          color: #fff;
          animation: fadeIn 0.3s ease-in-out both;
          display: flex;
          align-items: center;
        }
        .close-button {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          border: 1px solid #fff;
          border-radius: 50%;
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default NudgeHiResContentPopup;
