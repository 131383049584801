import { parsePlainTextToRichText } from '../../utils/string';

export interface IInspirationContentProps {
  header?: string;
  title?: string;
  content?: string;
}

const InspirationContent = ({
  header,
  title,
  content
}: IInspirationContentProps) => {
  return (
    <div className="copy inspiration-content">
      {header && (
        <p>
          <strong>{header}</strong>
        </p>
      )}
      {title && <p>{parsePlainTextToRichText(title, true)}</p>}
      {content && content.trim() !== (title || '').trim() && (
        <p>{parsePlainTextToRichText(content)}</p>
      )}
      <style jsx>{`
        .copy :global(a) {
          color: inherit;
          text-decoration: none;
          border-bottom: 1px dashed #ccc;
        }
        .copy :global(a:hover) {
          border-bottom: 1px solid #000;
        }
      `}</style>
    </div>
  );
};

export default InspirationContent;
