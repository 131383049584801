import { mobileBreakPoint } from '.';
import { assetBaseUrl } from '../../../config';

const GustoLuxeHubStyle = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Optima';
      src: url('${assetBaseUrl}/fonts/Optima.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    body {
      font-family: 'Optima', 'Arial';
    }

    button.close,
    button.popup-close,
    button.btn-close,
    .close-container,
    input:not(:placeholder-shown)[type="password"] {
      font-family: 'Arial' !important;
    }

    @media (min-width: ${mobileBreakPoint}px) {
      .Login .advisor-hub.layout .header {
        background-size: auto 70%;
      }
    }

    @media (max-width: 768px) {
      .Login .content-wrapper {
        background: rgba(0, 0, 0, 0.5);
      }
      .Login .reset-pwd {
        color: #fff;
      }
    }
  `}</style>
);

export default GustoLuxeHubStyle;
