import { FaPlay } from 'react-icons/fa';
import { IInspiration } from '../../interfaces';
import InspirationHtml from './Html';
import InspirationSimple from './Simple';
import InspirationVideo from './Video';
import InspirationScene from './Scene';

export const renderInspiration = (inspiration: IInspiration) => {
  switch (inspiration.type) {
    case 'html':
      return <InspirationHtml inspiration={inspiration} />;
    case 'video':
      return <InspirationVideo inspiration={inspiration} />;
    case 'scene':
      if (inspiration.scene)
        return <InspirationScene inspiration={inspiration} />;
      else return <InspirationSimple inspiration={inspiration} />;
    default:
      return <InspirationSimple inspiration={inspiration} />;
  }
};

export const getInspirationIcon = (inspiration: IInspiration) => {
  switch (inspiration.type) {
    case 'video':
      return (
        <FaPlay
          className="video-icon inspiration-icon"
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            color: '#fff',
            transform: 'translate(-50%, -60%)',
            width: '50%',
            maxWidth: '100px',
            height: 'auto',
            filter: 'drop-shadow(0 0 2px rgba(0, 0, 0, 0.5))',
            opacity: 0.6
          }}
        />
      );
    default:
      return null;
  }
};
