import React from 'react';
import { FiShare } from 'react-icons/fi';
import { logEvent } from '../../../analytics';
import SimpleShareModal from '../../../components/Sharing/SimpleShareModal';
import { useTranslation } from '../../../i18n';
import { DID_SHARE_NUDGE } from '../../../utils/constants';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import { getCurrentHost, performNativeShare } from '../../../utils/window';
import { ThemeColor } from '../BrandStyle';

const NudgeShareButton = ({
  nudgeId,
  nudgeTitle,
  themeColor
}: {
  nudgeId: string;
  nudgeTitle: string;
  themeColor: ThemeColor;
}) => {
  const [showShare, setShowShare] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState<string>();
  const { t } = useTranslation();
  const onSend = () => {
    const onNativeShare = () => {
      logEvent(DID_SHARE_NUDGE, 'native', {
        nudgeId,
        shareUrl,
        message: nudgeTitle
      });
    };
    const onFailedNativeShare = () => {
      setShowShare(true);
      setTimeout(() => {
        setShowShare(false);
      }, 5000);
    };
    performNativeShare(
      {
        title: nudgeTitle,
        text: nudgeTitle,
        url: shareUrl
      },
      onNativeShare,
      onFailedNativeShare
    );
  };
  const onShare = (channel = '') => {
    logEvent(DID_SHARE_NUDGE, channel, {
      nudgeId,
      shareUrl,
      message: nudgeTitle
    });
  };

  React.useEffect(() => {
    if (nudgeId) {
      const originalUrl = `${getCurrentHost()}/nudge/${nudgeId}`;
      if (typeof getSimpleShortenedUrl === 'function') {
        getSimpleShortenedUrl(originalUrl)
          .then((url) => setShareUrl(url))
          .catch(() => setShareUrl(originalUrl));
      } else {
        setShareUrl(originalUrl);
      }
    }
  }, []);
  return (
    <>
      <button className="send-button" onClick={onSend}>
        <FiShare />
        {t('vb:SEND')}
      </button>
      <SimpleShareModal
        show={showShare}
        url={shareUrl}
        title={nudgeTitle}
        onShare={onShare}
      />
      <style jsx>
        {`
          .send-button {
            margin-top: 50px;
            border-radius: 6px;
            background-color: ${themeColor.btnPrimary};
            color: ${themeColor.btnPrimaryText};
            font-size: 1em;
            letter-spacing: 2px;
            height: 50px;
            padding: 10px 50px;
          }

          .send-button :global(svg) {
            width: auto;
            height: 18px;
            margin-right: 10px;
          }

          :global(.share-group .btnShareSpan) {
            color: #000;
            font-size: 16px;
            margin-top: 5px;
          }

          @media (max-width: 768px), (max-height:670px) {
            .send-button {
              padding: 8px 20px;
              position:fixed;
              bottom:-5px;
              margin:0 auto;
            }
          }
        `}
      </style>
    </>
  );
};

export default NudgeShareButton;
