const DiscoverSvgIcon = ({ classname }: { classname?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.65 38.55" className={classname}>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path d="M16 32a16 16 0 1116-16 16 16 0 01-16 16zm0-28a12 12 0 1012 12A12 12 0 0016 4z" />
        <path d="M25.287 26.675l2.615-3.026 13.741 11.873-2.615 3.026z" />
      </g>
    </g>
  </svg>
);

export default DiscoverSvgIcon;
