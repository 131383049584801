import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { SliderNext, SliderPrev } from '../../../components/Arrows';
import RDUVBStyle from '../../../components/BrandStyle/RDUStyle';
import PopupPage from '../../../components/PopupPage';
import {
  BRANDING_DEFAULT_SECONDARY_COLOR,
  chopardBrandId,
  getBrandStorybookHost,
  inspifyBrandId,
  rogerDubuisSgBoutiqueId,
  tiffanyBrandId
} from '../../../config';
import {
  IInspiration,
  IMainState,
  INudge,
  INudgeItem,
  IPopupState,
  IProduct,
  IVirtualBoutiqueConfig,
  Source
} from '../../../interfaces';
import { actionOpenVBPopup } from '../../../redux/actions';
import { isMobileViewport } from '../../../utils/deviceDetector';
import { getCurrentHost } from '../../../utils/window';
import { isProduct } from '../../utils/nudge';
import { getAssetPath, getThemeColor } from '../BrandStyle';
import { HubBrandStyle } from '../BrandStyle/hubBrandStyle';
import { logEvent } from './../../../analytics/index';
import {
  DID_CLICK_MICROSITE_ITEM,
  INS_NUDGE_ID,
  INS_PRODUCT_CURRENCY
} from './../../../utils/constants';
import NudgeHiResContentPopup from './NudgeHiResContentPopup';
import NudgeMeetingButton from './NudgeMeetingButton';
import NudgeProductThumb from './NudgeProductThumb';
import NudgeShareButton from './NudgeShareButton';
import NudgeStoryThumb from './NudgeStoryThumb';

interface NudgeMicroSiteProps {
  nudge: INudge;
  brandId: string;
  onClickVisit?: () => any;
  appointment?: boolean;
  containPopup?: boolean;
  onBookPrivateSession?: () => any;
  config?: IVirtualBoutiqueConfig;
  isNotPreConfiguredBrand?: boolean;
}

const NudgeMicroSite = ({
  brandId,
  nudge,
  onClickVisit,
  appointment,
  containPopup = true,
  onBookPrivateSession,
  config,
  isNotPreConfiguredBrand = false
}: NudgeMicroSiteProps) => {
  const router = useRouter();
  const sharable = router.query.sharable === 'true';
  const dispatch = useDispatch();
  const color = getThemeColor(brandId);
  if (isNotPreConfiguredBrand) {
    color.btnPrimary =
      config?.theme?.color?.secondary || BRANDING_DEFAULT_SECONDARY_COLOR;
    color.btnPrimaryText = '#ffffff'; // secondary color is always a dark color
  }
  const isLightMode = color?.nudgeLightMode;
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [hiResContent, setHiResContent] = useState<string>(null);
  const [posterUrl, setPosterUrl] = useState<string>(undefined);
  const popupState: IPopupState | undefined = useSelector(
    (state: IMainState) => state.clientState.vb?.popup
  );
  const language = nudge.languageCode;
  const content = nudge.content || [];
  const storeId = nudge.storeId;
  const storeExtraParam = storeId ? `&store=${storeId}` : '';
  const sharableExtraParam = sharable ? `&sharable=true` : '';
  const nudgeId = `&nudgeId=${nudge.id}`;
  const extraParams = `${storeExtraParam}${sharableExtraParam}${nudgeId}`;

  const brandsWithoutDiscoverMoreButton = [
    chopardBrandId,
    inspifyBrandId,
    tiffanyBrandId
  ];

  const sliderSettings = () => {
    const settings = {
      nextArrow: <SliderNext />,
      prevArrow: <SliderPrev />,
      dots: true
    };
    if (!isMobileScreen) {
      return {
        ...settings,
        focusOnSelect: true,
        centerMode: true,
        variableWidth: true
      };
    }
    return settings;
  };

  const renderThumbnail = (content: INudgeItem, classes: string) =>
    isProduct(content) ? (
      <NudgeProductThumb
        language={language}
        key={content.id}
        hideReferences={nudge?.hideReferences}
        content={content as IProduct}
        className={classes}
        onClick={onItemClick}
      />
    ) : (
      <NudgeStoryThumb
        key={content.id}
        content={content as IInspiration}
        className={classes}
        onClick={onItemClick}
      />
    );

  const logClickItem = (url: string) => {
    logEvent(DID_CLICK_MICROSITE_ITEM, DID_CLICK_MICROSITE_ITEM, {
      itemPath: url
    });
  };

  const openPopupPage = (path: string, useStorybookHost = false) => {
    const host = useStorybookHost
      ? getBrandStorybookHost(brandId)
      : getCurrentHost();
    const url = `${host}${path}?source=${Source.Nudge}${
      language ? `&language=${language.toLowerCase()}` : ''
    }${extraParams}`;
    dispatch(actionOpenVBPopup(url));
    logClickItem(url);
  };

  const openHiResPopup = (path: string, posterUrl: string) => {
    setHiResContent(path);
    setPosterUrl(posterUrl);
    logClickItem(path);
  };

  const handleProductNudgeItemClicked = (item: IProduct) => {
    openPopupPage(`/products/${item.id}`);
  };

  const handleInspirationNudgeItemClicked = (item: IInspiration) => {
    switch (item.context) {
      case 'recording': {
        openHiResPopup(item.videoUrl, item.flipImageUrl);
        break;
      }
      case 'stillshot': {
        openHiResPopup(item.campaignImgUrl, undefined);
        break;
      }
      default: {
        openPopupPage(`/inspirations/${item.id}`, item.type === 'scene');
      }
    }
  };

  const onItemClick = (item: INudgeItem) => {
    if (isProduct(item)) {
      handleProductNudgeItemClicked(item as IProduct);
    } else {
      handleInspirationNudgeItemClicked(item as IInspiration);
    }
  };

  React.useEffect(() => {
    setIsMobileScreen(isMobileViewport());
    window[INS_NUDGE_ID] = nudge?.id;
    window[INS_PRODUCT_CURRENCY] = nudge?.hidePrice ? 'NONE' : nudge?.currency;
    return () => {
      window[INS_NUDGE_ID] = undefined;
      window[INS_PRODUCT_CURRENCY] = undefined;
    };
  }, [nudge]);

  const getMeetingButtonProps = () => {
    const props = {
      onBookPrivateSession: onBookPrivateSession,
      appointment: appointment,
      themeColor: color
    };
    if (brandId === chopardBrandId) {
      delete props.onBookPrivateSession; // remove book appointment for chopard
    }
    const isPreConfigured = !isNotPreConfiguredBrand;
    if (!brandsWithoutDiscoverMoreButton.includes(brandId) && isPreConfigured)
      // onboarding brand can not discover more
      return { ...props, onClickVisit: onClickVisit };
    return props;
  };

  const getLogo = () => {
    if (isNotPreConfiguredBrand) {
      return isLightMode ? config?.logoUrl : config?.lightLogoUrl;
    }
    return `${getAssetPath(brandId)}/${
      isLightMode ? 'logo.png?v3' : 'logo-light.png?v3'
    }`;
  };

  return (
    <div className="NudgeMicrosite">
      <div className="logo">
        <img src={getLogo()} />
      </div>
      <div className="slider-container">
        {content.length === 1 ? (
          <div className="single-content">
            {renderThumbnail(content[0], 'activeSlide')}
          </div>
        ) : (
          <Slider {...sliderSettings()}>
            {content.map((c) => renderThumbnail(c, 'activeSlide'))}
          </Slider>
        )}
      </div>

      {sharable ? (
        <NudgeShareButton
          nudgeId={nudge.id}
          nudgeTitle={nudge.title}
          themeColor={color}
        />
      ) : (
        <NudgeMeetingButton {...getMeetingButtonProps()} />
      )}

      {popupState?.open && containPopup && <PopupPage />}
      {hiResContent && (
        <NudgeHiResContentPopup
          contentPath={hiResContent}
          posterUrl={posterUrl}
          onClose={() => setHiResContent(null)}
        />
      )}

      <style jsx>{`
        :global(
            .slick-dots li button:before,
            .slick-dots li.slick-active button:before
          ) {
          color: ${isLightMode ? '#666' : '#fff'};
        }
        :global(.slick-dots) {
          bottom: -5px;
        }
        :global(.slick-dots li) {
          width: 3px;
        }

        :global(.slick-list) {
          padding-bottom: 20px;
        }
        h1 {
          font-size: 1.2em;
          padding: 0 30px;
          font-weight: 300;
          margin-bottom: 20px;
          max-width: 100%;
          overflow: hidden;
        }
        .slider-container {
          width: 100%;
          margin: 0 auto;
        }
        .slider-container :global(a) {
          text-decoration: none;
          color: inherit;
        }
        .NudgeMicrosite :global(.slick-slider.hide) {
          display: none;
        }
        .NudgeMicrosite {
          background: ${color.nudgeBackgroundUrl
              ? `url(${color.nudgeBackgroundUrl})`
              : 'none'},
            ${isLightMode
              ? '#fff'
              : 'linear-gradient(0deg,rgba(44, 44, 44, 1) 0%,rgba(33, 33, 33, 1) 70%,rgba(0, 0, 0, 1) 100%)'};
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          color: #efefef;
          text-align: center;
          width: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: fixed;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          justify-content: space-around;
          padding-bottom: 10vh;
        }
        .NudgeMicrosite::-webkit-scrollbar {
          display: none;
        }
        .logo {
          height: 10vh;
          display: flex;
          flex-direction: column;
          justify-content: end;
        }
        .logo img {
          margin: 0 auto;
          width: auto;
          max-width: 65vw;
          max-height: 8vh;
        }
        .NudgeMicrosite :global(.activeSlide) {
          background-position: center center;
          background-size: cover;
          background-color: ${isLightMode ? '#fff' : '#000'};
          display: inline-block;
          position: relative;
          width: 324px;
          height: 450px;
          max-height: calc(70vh);
          max-width: calc(70vh * 0.72);
          overflow: hidden;
          border-radius: 10px;
          box-shadow: 0 5px 15px ${isLightMode ? 'rgba(0,0,0,.2)' : '#000'};
          border: ${isLightMode ? 'none' : ' 1px solid #333'};
          cursor: pointer;
        }
        .NudgeMicrosite :global(.slick-current .view),
        .NudgeMicrosite :global(.single-content .view) {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
        }

        .NudgeMicrosite :global(.caption) {
          background: rgb(0, 0, 0);
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
          text-align: left;
          position: absolute;
          top: auto;
          left: 0;
          min-height: 200px;
          right: 0;
          bottom: 0;
          padding: 10px;
          padding-top: 40px;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
        }

        .NudgeMicrosite :global(.modelCode) {
          font-size: 0.6em;
          display: block;
          margin-bottom: 8px;
          letter-spacing: 1px;
          opacity: 0.8;
        }

        .NudgeMicrosite :global(.title) {
          font-size: 0.8em;
          padding-right: 20px;
          text-shadow: 0 0 5px ${isLightMode ? '#fff' : '#000'};
        }

        .NudgeMicrosite :global(.storyThumb) {
          --background-color: #000;
          --text-color: #fff;
        }

        .NudgeMicrosite :global(.productThumb) {
          --background-color: ${isLightMode ? '#fff' : '#000'};
          --text-color: ${isLightMode ? '#000' : '#fff'};
        }
        .NudgeMicrosite :global(.btn.discover-more) {
          background-color: var(--background-color);
          color: var(--text-color);
          border: 0.5px solid var(--text-color);
          position: absolute;
          top: 10px;
          right: 10px;
          opacity: 0;
          border-radius: 0;
          font-size: 8px;
          letter-spacing: 1px;
          padding: 0.6rem 1.2rem;
          transition: opacity 1.5s ease-in-out;
        }
        .NudgeMicrosite :global(.storyThumb .title) {
          overflow: hidden;
          line-height: 1.4em;
          max-height: 7em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        .NudgeMicrosite :global(.slick-slide.slick-current .btn.discover-more) {
          opacity: 0.8;
        }
        .NudgeMicrosite :global(.slick-slide.slick-current span.caption) {
          opacity: 1 !important;
        }

        .NudgeMicrosite
          :global(.slick-slide::not(.slick-current) span.caption) {
          opacity: 0 !important;
        }

        .NudgeMicrosite :global(.slick-slide span.caption) {
          opacity: 0;
          transition: opacity 1.5s ease-in-out;
        }
        .NudgeMicrosite
          :global(.slick-slide::not(.slick-current) .btn.discover-more) {
          opacity: 0;
        }
        .NudgeMicrosite .single-content :global(.btn.discover-more) {
          opacity: 0.8;
        }
        .NudgeMicrosite :global(.productThumb .caption) {
          text-transform: uppercase;
          min-height: 200px;
          color: ${isLightMode ? '#969696' : 'inherit'};
          background: ${isLightMode
            ? 'linear-gradient(180deg, rgba(255,255,255, 0) 0%, #fff 100%)'
            : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%)'};
        }

        @media (min-width: 768px) {
          h1 {
            font-size: 28px;
            letter-spacing: 1px;
            max-width: 600px;
            margin-bottom: 30px;
          }
        }
        @media (min-width: 768px) {
          .NudgeMicrosite {
            padding-bottom: 0;
          }
          .NudgeMicrosite :global(.activeSlide) {
            width: 400px;
            height: 600px;
            padding: 0;
            overflow: hidden;
            position: relative;
            transition: all 0.3s ease;
          }
          .NudgeMicrosite :global(.caption) {
            font-size: 1.2em;
          }
          .NudgeMicrosite :global(.single-content) {
            padding: 30px;
          }
          .NudgeMicrosite :global(.slick-slide) {
            -webkit-animation: fade-out 0.5s ease-out both;
            animation: fade-out 0.5s ease-out both;
            padding: 30px;
          }
          .NudgeMicrosite :global(.slick-current) {
            -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
              both;
            animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          }
          .NudgeMicrosite :global(.single-content .activeSlide) {
          }

          @-webkit-keyframes fade-in {
            0% {
              opacity: 0.3;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes fade-in {
            0% {
              opacity: 0.3;
            }
            100% {
              opacity: 1;
            }
          }
          @-webkit-keyframes fade-out {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0.3;
            }
          }
          @keyframes fade-out {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0.3;
            }
          }
        }

        @media (max-height: 670px) {
          .NudgeMicrosite {
            padding-top: 6vh;
            padding-bottom: 6vh;
            font-size: 14px;
          }
        }
      `}</style>

      {storeId === rogerDubuisSgBoutiqueId && <RDUVBStyle />}
      <HubBrandStyle brandId={brandId} />
    </div>
  );
};

export default NudgeMicroSite;
