import { GrNext, GrPrevious } from 'react-icons/gr';

const ArrowStyle = ({
  position = 'absolute'
}: {
  position?: 'fixed' | 'absolute';
}) => (
  <style jsx global>{`
    .slider-arrow {
      background: #fff;
      width: 120px;
      height: 120px;
      line-height: 120px;
      border-radius: 100%;
      position: ${position};
      top: 50%;
      transform: translate(0, -50%);
      z-index: 40;
      opacity: 0.4;
      box-shadow: 0 0 10px #000;
      cursor: pointer;
    }
  `}</style>
);

export const SliderNext = ({
  onClick,
  position
}: {
  onClick?: () => any;
  position?: 'fixed' | 'absolute';
}) => (
  <div className="slider-arrow slider-next" onClick={onClick}>
    <GrNext />
    <ArrowStyle position={position} />
    <style jsx>{`
      .slider-arrow {
        right: -85px;
        text-align: left;
        padding-left: 15px;
      }
    `}</style>
  </div>
);

export const SliderPrev = ({
  onClick,
  position
}: {
  onClick?: () => any;
  position?: 'fixed' | 'absolute';
}) => (
  <div className="slider-arrow slider-prev" onClick={onClick}>
    <GrPrevious />
    <ArrowStyle position={position} />
    <style jsx>{`
      .slider-arrow {
        left: -85px;
        text-align: right;
        padding-right: 15px;
      }
    `}</style>
  </div>
);
