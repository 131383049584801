import { Col, Container, Image, Row } from 'react-bootstrap';
import { IInspiration } from '../../interfaces';
import InspirationContent from './InspirationContent';
import { getDisplayedContext } from '../../utils/inspiration';
import { useTranslation } from '../../i18n';

const InspirationSimple = ({ inspiration }: { inspiration: IInspiration }) => {
  const { title } = inspiration;
  const content = inspiration.description;
  const image = inspiration.flipImageUrl;
  const header = getDisplayedContext(inspiration);
  const hasContent =
    header ||
    (title && title !== '' && title.length > 1) ||
    (content && content !== '' && content.length > 1);
  const autoTranslated =
    (inspiration.tagList || []).indexOf('auto_translated') > -1;
  const { t } = useTranslation();
  return (
    <div className="content content-simple">
      <Container>
        <Row className="align-items-center row-container">
          <Col xs={12}>
            <Row className="align-items-center content-container">
              <Col xs={12} md={hasContent ? 6 : 12} className="media-container">
                <Image src={image} alt={image} fluid={true} />
              </Col>
              {hasContent && (
                <Col xs={12} md={6}>
                  <InspirationContent
                    header={header}
                    title={title}
                    content={content}
                  />
                </Col>
              )}
              {autoTranslated && (
                <p className="auto-translated">{t('auto_translated')}</p>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <style jsx>
        {`
          .auto-translated {
            position: absolute;
            right: 10px;
            bottom: 10px;
            margin: 0;
            font-style: italic;
            font-size: 12px;
          }
        `}
      </style>
    </div>
  );
};

export default InspirationSimple;
