import { Row, Spinner, Col } from 'react-bootstrap';

const Placeholder = () => {
  return (
    <Row className="placeholder-container text-center align-items-center">
      <Col xs={12}>
        <Spinner animation="border" />
      </Col>
      <style jsx>
        {`
          :global(.placeholder-container) {
            position: fixed;
            height: 100vh;
            right: 0;
            left: 0;
            top: 0;
            z-index: 100;
            background-color: rgba(255, 255, 255, 0.8);
          }
        `}
      </style>
    </Row>
  );
};

export default Placeholder;
