import { last } from 'lodash';
import { BsChevronRight } from 'react-icons/bs';
import { INudgeItem, IProduct } from '../../../interfaces';
import { getProductDisplayName } from '../../../utils/product';

interface NudgeProductThumbProps {
  language: string;
  hideReferences?: boolean;
  content: IProduct;
  className: string;
  onClick: (item: INudgeItem) => void;
}

const NudgeProductThumb = ({
  language,
  hideReferences,
  content,
  className,
  onClick
}: NudgeProductThumbProps) => {
  let displayImageUrl = content.previewImageUrl;

  try {
    const imageId = last(
      decodeURIComponent(content.previewImageUrl).split('/')
    );
    displayImageUrl =
      content.previewImageUrl.split(imageId)[0] +
      encodeURIComponent('1000x1000/') +
      imageId;
  } catch (error) {
    console.log(error);
  }

  return (
    <div
      className={`productThumb ${className}`}
      style={{
        backgroundImage: `url(${displayImageUrl})`
      }}
    >
      <button className="btn btn-outline discover-more">DISCOVER MORE</button>
      <span className="caption">
        <span className="title">
          <span className="modelCode">
            {content.productFamily && (
              <>
                {content.productFamily}
                {` `}
                {!hideReferences && (
                  <BsChevronRight style={{ marginTop: '-3px' }} />
                )}
                {` `}
              </>
            )}
            {!hideReferences && content.modelCode}
          </span>
          {getProductDisplayName(content, language)}
        </span>
      </span>
      <span className="view" onClick={() => onClick(content)} />
    </div>
  );
};

export default NudgeProductThumb;
