import { getInspirationIcon } from '../../../components/Inspiration/common';
import { IInspiration, INudgeItem } from '../../../interfaces';

export interface NudgeStoryThumbProps {
  content: IInspiration;
  className: string;
  onClick: (item: INudgeItem) => void;
}

const NudgeStoryThumb = ({
  content,
  className,
  onClick
}: NudgeStoryThumbProps) => {
  return (
    <div
      className={`storyThumb ${className}`}
      style={{
        backgroundImage: `url(${content.flipImageUrl})`
      }}
    >
      <button className="btn btn-outline discover-more">DISCOVER MORE</button>
      {getInspirationIcon(content)}
      <span className="caption">
        <span className="title">{content.title}</span>
      </span>
      <span className="view" onClick={() => onClick(content)} />
    </div>
  );
};

export default NudgeStoryThumb;
