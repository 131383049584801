export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const isValidEmailId = (email: string) =>
  email && emailRegex.test(email.trim());
export const isValidEmail = (email: string) =>
  email && /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim());

export const isValidName = (name: string) =>
  name && name.trim().length > 0 && !/<script/i.test(name);

export const isValidPhoneNumber = (number: string) =>
  number &&
  atLeastHasNonZeroNumber(number) &&
  /^\+?([0-9]{2})\)?[-. ]?([0-9]+)[-. ]?([0-9]+)?[-. ]?([0-9]+)?$/.test(
    number.trim()
  );

export const isValidCountryCode = (countryCode: string) =>
  countryCode && /^\+?[0-9]+$/.test(countryCode.trim());

export const isValidMessage = (message: string) => !/<script/i.test(message);

export const isAllowedForPhone = (input: string) => {
  if (input === '') return true;
  return input.match(/^[0-9-+]+$/) != null;
};

export const isAllowedForEmail = (input: string) => {
  if (input === '') return true;
  return input.match(/^[a-z0-9.@_-]+$/) != null;
};

export const isAllowedForFileName = (input: string) => {
  if (input === '') return true;
  return input.match(/^[\w_-\s]+$/) != null;
};

export const cleanUpFileName = (input: string) => {
  if (!input) return;
  const name = input.replace(/\.(?=.*\.)/g, '');
  return name.replace(/[^a-zA-Z0-9-_. ]/g, '');
};

export const isAllowedForName = (input: string) => {
  if (input === '') return true;
  return input.match(/[&\/\\#,!+|;`=_()\[\]$~%":*?^<>{}0-9@]/) == null;
};
export const isAllowedForTagName = (input: string) => {
  if (input === '') return true;
  return input.match(/[&\/\\#,!+|;`=()\[\]$~%":*?^<>{}@]/) == null;
};
export const isAllowedForAddress = (input: string) => {
  if (input === '') return true;
  return input?.match(/[\\+\[\]$~%"*?^<>]/) == null;
};

export const isValidAddress = (address: string) =>
  isValidMessage(address) && isAllowedForAddress(address);

export const atLeastHasNonZeroNumber = (input: string) =>
  input.match(/(0*[1-9]+0*)+/) != null;

//minium 8 characters, maximum 128, at least 1 number, 1 uppercase, 1 lowercase, accept special characters
export const isValidPasswordAWS = (password: string) =>
  password.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*()_+\d]{8,128}$/
  );

export const isNotEmpty = (input: string) =>
  input && input?.trim()?.length !== 0;

// eslint-disable-next-line no-control-regex
export const isLatinString = (input: string) => !/[^\u0000-\u007f]/.test(input);

export const isValidPhoneNumberPart = (number: string) =>
  number &&
  atLeastHasNonZeroNumber(number) &&
  /^[1-9]?[0-9]{4,20}$/.test(number.trim());

// function to check if string is a valid url
export const isValidWebAddress = (url: string) => {
  let newUrl = url;
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    newUrl = 'http://' + url;
  }
  try {
    new URL(newUrl);
    return true;
  } catch (e) {
    return false;
  }
};

export const fixUrlAddress = (url: string) => {
  if(!url) return url;
  if (!url.startsWith('http')) {
    return `https://${url}`;
  }
  return url;
};

