const VacHubStyle = () => (
  <style jsx global>{`
    .btn,
    .visit-button,
    .appointment-button {
      border-radius: 0 !important;
    }
  `}</style>
);

export default VacHubStyle;
